// import HomeView from '../views/Landing.vue'
// import NProgress from 'nprogress';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [

  {
  path: '/register',
  name: 'register',
  component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue')
  },
  {
    path: '/staff/register',
    name: 'staffregister',
    component: () => import(/* webpackChunkName: "staffregister" */ '../views/StaffRegisterView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: "/activate/:id",
    name: "activateaccount",
    component: () => import(/* webpackChunkName: "activateaccount" */ '../views/ActivateAccountView.vue')
  },
  {
    path: "/extend_booking/:id",
    name: "extendbooking",
    component: () => import(/* webpackChunkName: "extendbooking" */ '../views/ExtendBookingView.vue')
  },
  {
    path: '/driverpreview',
    name: 'driverpreview',
    component: () => import(/* webpackChunkName: "driverpreview" */ '../views/DriverPreviewView.vue')
  },
  {
    path: '/userpreview',
    name: 'userpreview',
    component: () => import(/* webpackChunkName: "userpreview" */ '../views/UserPreviewView.vue')
  },
  {
    path: '/user/travels',
    name: 'usertravels',
    component: () => import(/* webpackChunkName: "usertravels" */ '../views/PetsTravelsView.vue')
  },
  {
    path: '/user/travel/:id',
    name: 'usertravel',
    component: () => import(/* webpackChunkName: "usertravels" */ '../views/UserTravelView.vue')
  },
  {
    path: '/user/charges',
    name: 'usercharges',
    component: () => import(/* webpackChunkName: "usertravels" */ '../views/UserChargesView.vue')
  },
  {
    path: '/driver/payments',
    name: 'driverpayments',
    component: () => import(/* webpackChunkName: "usertravels" */ '../views/DriverPaymentsView.vue')
  },
  {
    path: '/user/requests',
    name: 'userrequests',
    component: () => import(/* webpackChunkName: "requests" */ '../views/UserRequestsView.vue')
  },
  {
    path: '/travelselection',
    name: 'travelselection',
    component: () => import(/* webpackChunkName: "travelselection" */ '../views/TravelSelectionView.vue')
  },
  {
    path: "/",
    name: "home",
    props: true,
    component: () => import(/* webpackChunkName: "travelselection" */ '../views/Landing.vue')
  },
  {
    path: "/travelbooking",
    name: "travelbooking",
    component: () => import(/* webpackChunkName: "travelbooking" */ '../views/TravelBookingView.vue')
  },
  {
    path: "/paymentsuccess/:payment_id",
    name: "paymentsuccess",
    component: () => import(/* webpackChunkName: "paymentsuccess" */ '../views/PaymentSuccessView.vue')
  },
  {
    path: "/user/info",
    name: "userinfo",
    component: () => import(/* webpackChunkName: "user_info" */ '../views/UserInfoView.vue')
  },
  {
    path: "/user/passanger/:id",
    name: "user_passanger",
    component: () => import(/* webpackChunkName: "user_passanger" */ '../views/UserPassangerView.vue')
  },
  {
    path: '/user/travel_chat/:id',
    name: 'userPassangerChat',
    component: () => import(/* webpackChunkName: "userChat" */ '../views/UserPassangerChatView.vue')
  },
  {
    path: "/user",
    name: "useraccount",
    component: () => import(/* webpackChunkName: "useraccount" */ '../views/UserAccount.vue')
  },
  {
    path: "/checkin/:passanger_id",
    name: "checkin",
    component: () => import(/* webpackChunkName: "checkin" */ '../views/CheckInView.vue')
  },
  {
    path: "/travel_progress",
    name: "travel_progress",
    component: () => import(/* webpackChunkName: "travel_progress" */ '../views/TravelInProgressView.vue')
  },
  {
    path: "/pets",
    name: "pets",
    component: () => import(/* webpackChunkName: "checkin" */ '../views/PetsView.vue')
  },
  {
    path: "/newpet",
    name: "new_pet",
    component: () => import(/* webpackChunkName: "checkin" */ '../views/New_petView.vue')
  },
  {
    path: "/offer/payment/:offer_id",
    name: "offerPayment",
    component: () => import(/* webpackChunkName: "paymentsuccess" */ '../views/OfferPaymentView.vue')
  },
  {
    path: "/paymentsuccess/:payment_id",
    name: "paymentsuccess",
    component: () => import(/* webpackChunkName: "paymentsuccess" */ '../views/PaymentSuccessView.vue')
  },
  {
    path: "/user/passanger/evaluation/:id",
    name: "evaluation",
    component: () => import(/* webpackChunkName: "evaluation" */ '../views/EvaluationView.vue')
  },


 
/*---------------------------------------------------------------------------------------------------------------------------------*/

//Rutas de Drivers

/*---------------------------------------------------------------------------------------------------------------------------------*/
  {
    path: '/cochedriver',
    name: 'cochedriver',
    component: () => import(/* webpackChunkName: "cochedriver" */ '../views/Dashboard_DriverView/CocheDriverView.vue')
  },
  {
    path: '/estadisticasdriver',
    name: 'estadisticasdriver',
    component: () => import(/* webpackChunkName: "estadistcasdriver" */ '../views/Dashboard_DriverView/EstadisticasDriverView.vue')
  },
  {
    path: '/facturaciondriver',
    name: 'facturaciondriver',
    component: () => import(/* webpackChunkName: "facturaciondriver" */ '../views/Dashboard_DriverView/FacturacionDriverView.vue')
  },
  {
    path: '/newtravel',
    name: 'newtravel',
    component: () => import(/* webpackChunkName: "newtravel" */ '../views/Dashboard_DriverView/NewTravelView.vue')
  },
  {
    path: '/driver/requests',
    name: 'requests',
    component: () => import(/* webpackChunkName: "requests" */ '../views/Dashboard_DriverView/RequestsView.vue')
  },
  {
    path: '/driver/travel/:id',
    name: 'drivertravel',
    component: () => import(/* webpackChunkName: "drivertravel" */ '../views/Dashboard_DriverView/DriverTravelView.vue')
  },
  {
    path: '/driver/travel/evaluation/:id',
    name: 'driver_evaluation',
    component: () => import(/* webpackChunkName: "driver_evaluation" */ '../views/Dashboard_DriverView/DriverEvaluationView.vue')
  },
  {
    path: '/driver/travel_chat/:id',
    name: 'driverChat',
    component: () => import(/* webpackChunkName: "driverChat" */ '../views/Dashboard_DriverView/DriverChatView.vue')
  },
  {
    path: '/driver',
    name: "driveraccount",
    component: () => import(/* webpackChunkName: "driveraccount" */ '../views/Dashboard_DriverView/DriverAccount.vue')
  },
  {
    path: "/driver/info",
    name: "driverinfo",
    component: () => import(/* webpackChunkName: "driver_info" */ '../views/Dashboard_DriverView/DriverInfoView.vue')
  },
  {
    path: "/driver/travels",
    name: "drivertravels",
    component: () => import(/* webpackChunkName: "perfildriver" */ '../views/Dashboard_DriverView/DriverTravelsView.vue')
  },
  {
    path: "/driver/trips",
    name: "drivertrips",
    component: () => import(/* webpackChunkName: "perfildriver" */ '../views/Dashboard_DriverView/TripsView.vue')
  },
  {
    path: "/driver/cars",
    name: "driver_cars",
    component: () => import(/* webpackChunkName: "driver_cars" */ '../views/Dashboard_DriverView/DriverCarsView.vue')
  },
  {
    path: "/car/:id",
    name: "car",
    component: () => import(/* webpackChunkName: "car" */ '../views/CarView.vue')
  },
  {
    path: "/pet/:id",
    name: "pet",
    component: () => import(/* webpackChunkName: "car" */ '../views/PetView.vue')
  },
  {
    path: "/car/:id/documents",
    name: "car_docs",
    component: () => import(/* webpackChunkName: "car" */ '../components/Owner/CarDocuments.vue')
  },
  {
    path: "/car/:id/travels",
    name: "car_travels",
    component: () => import(/* webpackChunkName: "car" */ '../components/Owner/CarTravels.vue')
  },
  {
    path: "/car/:id/drivers",
    name: "car_drivers",
    component: () => import(/* webpackChunkName: "car" */ '../components/Owner/CarDrivers.vue')
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import(/* webpackChunkName: "car" */ '../views/SettingsView.vue')
  },


  /*---------------------------------------------------------------------------------------------------------------------------------*/

  //Rutas de Owner

  /*---------------------------------------------------------------------------------------------------------------------------------*/

  {
    path: "/new_car",
    name: "new_car",
    component: () => import(/* webpackChunkName: "new_car" */ '../views/NewCarView.vue')
  },



/*---------------------------------------------------------------------------------------------------------------------------------*/

  //Rutas de Empresa

/*---------------------------------------------------------------------------------------------------------------------------------*/
  {
    path: '/facturacionempresa',
    name: 'facturacionempresa',
    component: () => import(/* webpackChunkName: "driver" */ '../views/Dashboard_EmpresaView/FacturacionEmpresaView.vue')
  },
  {
    path: '/pagosempresa',
    name: 'pagosempresa',
    component: () => import(/* webpackChunkName: "driver" */ '../views/Dashboard_EmpresaView/PagosEmpresaView.vue')
  },
  {
    path: '/perfilesempresa',
    name: 'perfilesempresa',
    component: () => import(/* webpackChunkName: "driver" */ '../views/Dashboard_EmpresaView/PerfilesEmpresaView.vue')
  },
  {
    path: '/viajesempresa',
    name: 'viajesempresa',
    component: () => import(/* webpackChunkName: "driver" */ '../views/Dashboard_EmpresaView/ViajesEmpresaView.vue')
  },
  {
    path: '/empresa',
    name: 'empresa',
    component: () => import(/* webpackChunkName: "driver" */ '../views/Dashboard_EmpresaView/EmpresaView.vue')
  },
/*---------------------------------------------------------------------------------------------------------------------------------*/
  {
    path: '/viajesrealizados',
    name: 'viajesrealizados',  
    component: () => import(/* webpackChunkName: "driver" */ '../views/Dashboard_EmpresaView/EmpresaView.vue')
  },





  //Rutas de Usuarios
/*------------------------------------------------------------------------------------------------------------------------------
/*---------------------------------------------------------------------------------------------------------------------------------*/





]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//     // Start the route progress bar.
//     NProgress.start()
//   }
//   next()
// })

// router.afterEach(() => {
//   // Complete the animation of the route progress bar.
//   NProgress.done()
// })

export default router
