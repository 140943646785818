import axios from 'axios';
import auth from '@/logic/auth';
import router from '@/router';

const ENDPOINT_PATH = 'https://api.travipet.com/api/v1/';

axios.defaults.baseURL = ENDPOINT_PATH;
axios.interceptors.request.use(config => {
  config.headers.Authorization = `Bearer ${auth.getToken()}`;
  // NProgress.start();
  return config;
});

axios.interceptors.response.use(null, error => {
  if (error.response.status === 401 || error.response.status === 422) {
    auth.removeCookies();
    router.push({ name: 'login' });
  }
  // NProgress.done()
  return Promise.reject(error);
});

export default {
    async get(url) {
        return axios.get(url).then(response => {
            return JSON.parse(JSON.stringify(response));
    });
  },
    async get_params(url, params) {
      return axios.get(url, {params: JSON.stringify(params)}).then(response => {
          return JSON.parse(JSON.stringify(response));
  });
  },
  async post(url, data) {
      return axios.post(url, data,{
          headers: {
              'Content-Type': 'application/json'
          }
      }
      ).then(response => {
          return JSON.parse(JSON.stringify(response));
      });
    },
  async post_file(url, file, paramName, paramValue) {
    let formData = new FormData();
    formData.append('file', file);
    url += `?${paramName}=${paramValue}`;

    return axios.post(url, formData,{
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      return JSON.parse(JSON.stringify(response));
    })
  }
}