  <template>
    <Headers />
    <section class="hero-home">
      <div class="swiper-container hero-slider">
        <div class="swiper-wrapper dark-overlay">
          <div style="background-image:url(/frontend/img/photo/perro1.jpg)" class="swiper-slide"></div>
          <div style="background-image:url(/frontend/img/photo/perro3.jpeg)" class="swiper-slide"></div>
          <div style="background-image:url(/frontend/img/photo/perro4.jpeg)" class="swiper-slide"></div>
        </div>
      </div>
      <div class="container py-2 py-md-7 text-white z-index-20">
        <div class="row justify-content-center mb-2 mt-4">
          <div class="col-10 col-md-8 col-lg-6 text-center">
            <img src="@/assets/img/travipet_logo_horizontal.png" alt="TraviPet Logo" class="img-fluid travipet-logo">
          </div>
        </div>  
        <div class="row justify-content-center">

  <div class="search-bar p-3 p-lg-1 pl-lg-3 rounded shadow-sm">
      <div class="row align-items-center justify-content-center">
        <!-- Campo para Fecha -->
        <div class="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text border-0 shadow-0"><i class="fa fa-calendar"></i></span>
            </div>
            <input placeholder="¿Cuándo quieres viajar?" class=" form-control border-0 shadow-0 text-white"
              v-model="date" type="text" @focus="showDateTimePicker" id="datepicker" @blur="formatDate" />
          </div>
        </div>
        <!-- Campo para Origen -->
        <div class="col-12 col-lg-2 col-md-6 mb-3 mb-lg-0">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text  border-0 shadow-0"><i class="fa fa-map-marker-alt"></i></span>
            </div>
            <select v-model="origin" title="Origen" data-style="btn-form-control" class=" form-control border-0 shadow-0 text-white">
              <option selected disabled value="">Origen</option>
              <option v-for="city in cities" :value="city">{{ city }}</option>
            </select>
          </div>
        </div>
        <!-- Campo para Destino -->
        <div class="col-12 col-lg-2 col-md-6 mb-3 mb-lg-0">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text  border-0 shadow-0"><i class="fa fa-map-marker-alt"></i></span>
            </div>
            <select v-model="destination" title="Destino" data-style="btn-form-control"
              class=" form-control border-0 shadow-0 text-white">
              <option disabled value="" selected>Destino</option>
              <option v-for="city in cities" :value="city">{{ city }}</option>
            </select>
          </div>
        </div>
        <!-- Nuevo campo para Número de Mascotas -->
        <div class="col-12 col-lg-2 col-md-6 mb-3 mb-lg-0">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text  border-0 shadow-0"><i class="fa fa-paw"></i></span>
            </div>
            <input placeholder="Número de mascotas" type="number" class=" form-control border-0 shadow-0 text-white"
              v-model="numberOfPets" min="1" />
          </div>
        </div>
        <!-- Botón Buscar -->
        <div class="col-12 col-lg-2 col-md-6">
          <button class="btn btn-primary btn-block" @click="search">
            <img src="@/assets/img/dog_foot.png" class="nav-icon mr-2">Buscar
          </button>
        </div>
      </div>
    </div>
        </div>
      </div>
    </section>
    
    <div class="container position-relative justify-content-center mt-3 z-index-20 p-1 p-lg-6">
      <div id="usuario" role="tabpanel" class="tab-pane fade show active">
        <div class="container">
          <div class="text-center pb-lg-4">
            <p class="subtitle text-secondary text-center">Transporte y cuidado de mascotas </p>
            <h3 class="mb-6">El mejor cuidado de nuestro amigo</h3>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-3 mb-lg-0 text-center">
              <div class="px-0 px-lg-3">
                <div class="icon-rounded bg-primary-light mb-3">
                  <svg class="svg-icon text-primary w-2rem h-2rem">
                    <use xlink:href="#heart-1"></use>
                  </svg>
                </div>
                <h3 class="h5">Amor por las mascotas</h3>
                <p class="text-muted text-sm">
                  Hacemos un proceso personalizado de selección de nuestros colaboradores para asegurar el cuidado.
                  Esto garantiza que tu mascota estará siempre en las mejores manos.
                </p>
              </div>
            </div>
            <div class="col-lg-4 mb-3 mb-lg-0 text-center">
              <div class="px-0 px-lg-3">
                <div class="icon-rounded bg-primary-light mb-3">
                  <svg class="svg-icon text-primary w-2rem h-2rem">
                    <use xlink:href="#sedan-1"></use>
                  </svg>
                </div>
                <h3 class="h5">Transporte especializado</h3>
                <p class="text-muted text-sm">
                  Usamos material certificado para el cuidado de tu mascota, para el paseo como para su transporte.
                  Verificamos que el vehículo esté debidamente sanitizado, climatizado y cómodo para las mascotas.
                </p>
              </div>
            </div>
            <div class="col-lg-4 mb-3 mb-lg-0 text-center">
              <div class="px-0 px-lg-3">
                <div class="icon-rounded bg-primary-light mb-3">
                  <svg class="svg-icon text-primary w-2rem h-2rem">
                    <use xlink:href="#women-1"></use>
                  </svg>
                </div>
                <h3 class="h5">Nuestros colaboradores</h3>
                <p class="text-muted text-sm">
                  Buscamos por tí a la persona idónea para tu mascota, disponible a la hora y fecha que necesites. Y
                  si tu mascota se siente a gusto con alguien en particular, podemos agendar los paseos por el periodo
                  que requieras.
                </p>
              </div>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-primary" @click="$router.push('userpreview')">Saber más</button>
          </div>
        </div>

      </div>
    </div>
    <br>
    <!-- Divider Section-->
    <section class="py-7 position-relative dark-overlay"><img
        src="@/assets/img/photo/photo-1497436072909-60f360e1d4b1.jpg" alt="" class="bg-image">
      <div class="container">
        <div class="overlay-content text-white py-lg-5">
          <h3 class="display-3 font-weight-bold text-serif text-shadow mb-5">Listo para viajar?</h3><button
            class="btn btn-primary" @click="$router.push('login')"><img src="@/assets/img/dog_foot.png"
              class="nav-icon">Empecemos</button>
        </div>
      </div>
    </section>

    <Footer />
  </template>
  <script>
  import Headers from '../components/Headers.vue'
  import Footer from '../components/Footer.vue'
  import auth from '@/logic/auth';
  import req from "@/logic/req";
  import axios from 'axios';
  import { ShowAlert } from '@/logic/aux';

  export default {
    components: { Headers, Footer, ShowAlert},
      name:'Landing',
      data() {
      return {
        cities: [],
        origin: '',
        destination: '',
        date: null,
        inputType: 'text',
        numberOfPets: 1,  // Nuevo campo para número de mascotas
        travels: [],
      };
    },
      created() {
        axios.get('https://api.travipet.com/api/v1/cities')
        .then(response => {
          this.cities = response.data.cities;
        })
        .catch(error => {
          console.error(error);
        });
    },
      computed: {
      userLogged() {
        return auth.getUserLogged();
      },
      PersonType() {
        return auth.getPersonType();
      }
    },

    methods:{
      ShowAlert,
      showDateTimePicker(event) {
        // Change input type to datetime-local to show the picker
        event.target.type = "datetime-local";
      },
      formatDate(event) {
        const input = event.target;
        if (input.value) {
          // Parse the date value and format it to 'YYYY-MM-DD HH:mm'
          const dateObj = new Date(input.value);
          const formattedDate = this.formatDateToDisplay(dateObj);
          this.date = formattedDate;
          // Change back the input type to text to prevent showing the 'T'
          input.type = "text";
          input.value = formattedDate;
        }
      },
      formatDateToDisplay(dateObj) {
        // Format date to 'YYYY-MM-DD HH:mm'
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const day = String(dateObj.getDate()).padStart(2, "0");
        const hours = String(dateObj.getHours()).padStart(2, "0");
        const minutes = String(dateObj.getMinutes()).padStart(2, "0");
        return `${year}-${month}-${day} ${hours}:${minutes}`;
      },
      
      validateDateFormat(date) {
      const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
      return regex.test(date);
    },


      search() {
        // Verificar si el usuario está logueado
        if (!this.userLogged) {
          // Si no está logueado, redirigir a la página de inicio de sesión
          this.$swal({
            html: `Por favor, inicie sesión para continuar`,
            icon: 'warning'
          }).then(() => {
            this.$router.push({ name: 'login' });
          });
          return;
        }
        // Validar el formato de la fecha
      if (!this.validateDateFormat(this.date)) {
        this.$swal({
          html: `La fecha debe tener el formato 'YYYY-MM-DD HH:mm'`,
          icon: 'error'
        });
        return;
      }

      // Validar el número de mascotas
      if (this.numberOfPets > 5) {
        this.$swal({
          html: `El número de mascotas no puede ser mayor a 5`,
          icon: 'error'
        });
        return;
      }
        req.post('request', {
          origin: this.origin,
          destination: this.destination,
          date: this.date,
          num_pets: this.numberOfPets
        }).then(res => {
          console.log(res);
          this.ShowAlert(
            'Solicitud Enviada',
            '',
            res.status,
            '/user/requests'
          );
        }).catch(err => {
          console.error(err);
        });
      },
      ShowAlertok(){
        this.$swal({
          html: `Mensaje <br> <b> Sesión Cerrada </b>`,
          icon: 'success'           
        }).then(function(){
          auth.deleteUserLogged()
          window.location.href="/";
        })
      }
    }

  }

  </script>

    

  <style scoped>
  .noborder{
    text-align: left;
    outline: none;
    border: none;
    width: 100%;
    background-color: transparent;
  }

  .search-bar {
    background-color: rgba(255, 255, 255, 0); /* Fondo con 70% de opacidad */
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .input-group-text {
    background-color: rgba(255, 255, 255, 0.4); /* Fondo con 70% de opacidad */
    border: none;
  }

  .input-group-text i {
    color: #ffffff;
  }

  .form-control {
    background-color: rgba(255, 255, 255, 0.4); /* Fondo con 70% de opacidad */
    border: none;
    color: white; /* Color del texto blanco */
  }
  .form-control::placeholder {
    color: white; /* Color del placeholder blanco */
    opacity: 1; /* Asegura que el color del placeholder sea completamente blanco */
  }
  .form-control:focus {
    background-color: rgba(255, 255, 255, 0.4); /* Mantiene el fondo con 70% de opacidad al enfocar */
    color: white; /* Mantiene el color del texto blanco al enfocar */
  }
  select.form-control {
  background-color: rgba(255, 255, 255, 0.4); /* Fondo con 60% de opacidad */
}

select.form-control:focus {
  background-color: rgba(255, 255, 255, 0.4); /* Mantiene el fondo con 60% de opacidad al enfocar */
}

select.form-control option {
  background-color: rgba(255, 255, 255, 0.4); /* Fondo con 60% de opacidad para las opciones */
  color: black; /* Color del texto de las opciones */
}
  
  .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  .travipet-logo {
  max-width: 100%;
  height: auto;
}
  @media (max-width: 360px) {
  .search-bar {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .input-group {
    margin-bottom: 0rem; /* Reduce el espaciamiento vertical entre los campos */
  }
}
  </style>