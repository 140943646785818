<template>
 
    <!-- Main block - menus, subscribe form-->
    <div class="py-1 bg-gray-200 text-muted">
      <div class="container">
        <div class="row text-sm">
          <div class="col-lg-4 mb-1 mb-lg-0">
            <div class="font-weight-bold text-uppercase text-dark mb-1">TraviPet</div>
            <p>Somos gente que ama a los animales, los trasnportamos donde y cuando lo necesites.</p>
            <ul class="list-inline">
              <li class="list-inline-item"><a href="#" target="_blank" title="facebook"
                  class="text-muted text-hover-primary"><i class="fab fa-facebook"></i></a></li>
              <li class="list-inline-item"><a href="#" target="_blank" title="instagram"
                  class="text-muted text-hover-primary"><i class="fab fa-instagram"></i></a></li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-6 mb-2 mb-lg-0">
            <h6 class="text-uppercase text-dark mb-1"></h6>
            <ul class="list-unstyled">
              <li><a href="/" class="text-muted">¿Como usar nuestros servicios?</a></li>
              <li><a href="/" class="text-muted">Preguntas Frecuentes </a></li>
              <li><a href="/" class="text-muted">Como ser un Conductor en TraviPet </a></li>
            </ul>
          </div>
          <div class="col-lg-2 col-md-6 mb-1 mb-lg-0">
            <ul class="list-unstyled">
              <li><a href="/" class="text-muted">Política de cookies</a></li>
              <li><a href="/" class="text-muted">Terminos y condiciones</a></li>
              <li><a href="/" class="text-muted">Contacto </a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Copyright section of the footer-->
    <div class="py-2 font-weight-light bg-gray-800 text-gray-300">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center text-md-left">
            <p class="text-sm mb-md-0">&copy; 2022 Travi. Todos los derechos reservados.</p>
          </div>
        </div>
      </div>
    </div>

  <!-- /Footer end-->
</template>

<script>
export default {
    name:'Footer'
}
</script>

<style>

</style>